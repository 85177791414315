import { createSelector } from '@reduxjs/toolkit';
import {
    selectAboutPage,
    selectConsortiumPage,
    selectEducationPage,
    selectProfstandsrdsPage,
    selectWorkingGroupsPage,
} from 'store/cms.slice';


export const getAboutHeader = createSelector(
    selectAboutPage,
    (result) => result?.data?.Header ?? {}
);

export const getConsortiumHeader = createSelector(
    selectConsortiumPage,
    (result) => result?.data?.Header ?? {}
);

export const getEducationHeader = createSelector(
    selectEducationPage,
    (result) => result?.data?.Header ?? {}
);

export const getProfstandardsHeader = createSelector(
    selectProfstandsrdsPage,
    (result) => result?.data?.Header ?? {}
);

export const getWorkingGroupsHeader = createSelector(
    selectWorkingGroupsPage,
    (result) => result?.data?.header ?? {}
);
