import React, { useState } from 'react';
import { Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/use-selector';
import { formatToMon3 } from 'utils/date';
import { FILE_API } from 'utils/request';
import ErrorBoundaryLine from 'components/error-boundary-line';
import { useNewsDrawerContext } from 'components/drawer-news-details/NewsDetailsDrawer';
import { useGetNewsQuery } from './NewsBlock.slice';
import './style.less';


const NewsBlock = (): React.ReactElement => {
    const { t } = useTranslation('guest-news');
    const isMobile = useSelector((state) => state.common.isMobile);
    const { data: news } = useGetNewsQuery();
    const [page, setPage] = useState(1);
    const { show } = useNewsDrawerContext();

    const PAGE_SIZE = isMobile ? 3 : 10;

    const paginationSlicer = (newsList: any) => {
        const start = (page - 1) * PAGE_SIZE;
        const end = PAGE_SIZE + (page - 1) * PAGE_SIZE;
        
        if (Array.isArray(newsList) && newsList.length > 0) {
            return newsList.slice(start, end);
        }

        return [];
    };

    return (
        <div className="news-block wrapper">
            <div className="row align-center">
                <div className="news-block__title">
                    {t('news')}
                </div>
            </div>

            <div className="news-list">
                {
                    paginationSlicer(news).length > 0
                    && paginationSlicer(news).map((item) => (
                        <div className="list-item" key={item.id}>
                            {item.image && (
                                <img
                                    className="item-image"
                                    onClick={() => show(item)}
                                    alt="Превью"
                                    aria-hidden="true"
                                    src={FILE_API + item.image.formats?.small.url}
                                />
                            )}
                            <div className="item-content">
                                <div className="content-header">
                                    <span className="header-date">
                                        {formatToMon3(item.pubDate)}
                                    </span>
                                </div>
                                <div className="content-body">
                                    <div
                                        className="title"
                                        onClick={() => show(item)}
                                        aria-hidden="true"
                                    >
                                        {item.title}
                                    </div>
                                    <div className="description">
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <Pagination
                onChange={setPage}
                current={page}
                defaultCurrent={1}
                total={news?.length}
                pageSize={PAGE_SIZE}
            />
        </div>
    );
};

export default NewsBlock;

export const NewsBlockSafe = () => (
    <ErrorBoundaryLine
        componentTitle="Новости"
    >
        <NewsBlock />
    </ErrorBoundaryLine>
);
