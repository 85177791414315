import React from 'react';
import { useSelector } from 'store/use-selector';
import { getCitationBlock } from 'store/cms.slice';
import './style.less';


const OurCommonTask = () => {
    const citationBlock = useSelector(getCitationBlock);

    return (
        <div className="our-common-task wrapper">
            <div className="task-content">
                <div className="message">
                    {citationBlock.authorQuote}
                </div>
                <div className="user">
                    <div className="name">
                        {citationBlock.authorName}
                    </div>
                    <div className="pos">
                        {citationBlock.authorPosition}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurCommonTask;
