import React, {
    createContext, useCallback, useContext, useState
} from 'react';
import { Drawer } from 'antd';
import bem from 'easy-bem';
import { useSelector } from 'store/use-selector';
import { formatDate } from 'utils/date';
import { FILE_API } from 'utils/request';
import { selectThreeNews } from './NewsDetailsDrawer.slice';

import './style.less';


type NDPProps = {
    children: React.ReactNode
}

// TODO: убрать контекст
export const NewsDraverContext = createContext<any>(null);

export const NewsDrawerProvider = ({ children }: NDPProps) => {
    // @ts-ignore
    const list = useSelector((state: any) => selectThreeNews(state));
    const [isOpen, setIsOpen] = useState(false);
    const [details, setDetails] = useState<any>(null);
    const b = bem('news-details-drawer');

    const show = useCallback(
        (data?: any) => setIsOpen((v) => {
            if (v) {
                setDetails(null);
            } else {
                setDetails(data);
            }
            return !v;
        }),
        []
    );

    return (
        <NewsDraverContext.Provider value={{ show }}>
            {children}
            <Drawer
                className={b()}
                title="Новости"
                placement="right"
                size="large"
                visible={isOpen}
                onClose={() => show()}
            >
                {details && (
                    <>
                        <p className="detail-date">
                            {formatDate(details.pubDate)}
                        </p>
                        <header>
                            <h1>
                                {details.title}
                            </h1>
                        </header>
                        <figure>
                            <img
                                src={FILE_API + details.image?.formats?.large.url}
                                alt=""
                            />
                        </figure>
                        <strong>
                            {details.subTitle}
                        </strong>
                        <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: details.turboContent,
                            }}
                        />
                        <div>
                            {
                                details.links?.length > 0
                                && details.links.map((item: any) => (
                                    <>
                                        <a href={item.link}>
                                            {item.text}
                                        </a>
                                        <br />
                                    </>
                                ))
                            }
                        </div>
                    </>
                )}
                <div className="last-news">
                    <h4 className="last-news-title">
                        Последние новости
                    </h4>
                    <div className="news-grid">
                        {
                            list?.length > 0
                            && list.map((item: any) => (
                                <div
                                    aria-hidden="true"
                                    onClick={() => setDetails(item)}
                                    key={item.title}
                                    className="grid-item"
                                >
                                    <p className="item-date">
                                        {formatDate(item.pubDate)}
                                    </p>
                                    {item.image && (
                                        <img
                                            className="item-image"
                                            src={FILE_API + item.image?.formats?.large.url}
                                            alt={item.title}
                                        />
                                    )}
                                    <h4 className="item-title">
                                        {item.title}
                                    </h4>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Drawer>
        </NewsDraverContext.Provider>
    );
};

export const useNewsDrawerContext = () => useContext(NewsDraverContext);

export default NewsDrawerProvider;
