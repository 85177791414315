import qs from 'qs';
import { createSelector } from '@reduxjs/toolkit';
import { genericApi, RequestProps } from 'store/generic.service';
import { StrapiResponse } from 'utils/converters';


type RequestPropsLocal = RequestProps & {
    search?: string
    orderBy?: string
    regionsList?: any
    orgTypeList?: any
}

export const regionsToClient = (list: string[]) => {
    const districts: string[] = [];
    const regions: string[] = [];

    list.forEach((item) => {
        if (item.indexOf('-') === 0) {
            // область начинается с префикса RU-
            regions.push(item?.substring(1));
        } else {
            // регион/округ без префикса
            districts.push(item?.substring(1));
        }
    });

    return [districts, regions];
};

const toClient = (response: StrapiResponse):any => {
    const list = response.data.map((record) => {
        const org = record.attributes || {};

        return {
            id: record.id,
            orgFullName: org.name,
            orgShortName: null,
            organizationLevel: org.type,
            dateConsortium: null,
            subjectName: org.region,
            subjectRegion: org.federalDistrict,
            consortium: null,
            consortiumStatus: null,
            key: record.id
        };
    });

    return {
        list,
        count: response.meta?.pagination.total || 0
    };
};

const path = 'consortium-members';

export const consortiumMembersApi:any = genericApi.injectEndpoints({
    endpoints: (builder) => ({
        getConsortiumMembers: builder.query<StrapiResponse, RequestPropsLocal>({
            query: ({
                search = '',
                orgTypeList,
                regionsList,
                // statusDateList,
                orderBy = 'asc',
                pageSize = 20,
                page = 1
            }) => {
                const [districts, regions] = regionsToClient(regionsList);

                const query = qs.stringify({
                    filters: {
                        name: {
                            $containsi: search
                        },
                        type: {
                            $in: orgTypeList
                        },
                        region: {
                            $in: regions
                        },
                        federal_district: {
                            $in: districts
                        }
                    },
                    sort: [`name:${orderBy}`],
                    pagination: { pageSize, page }
                });

                return {
                    url: `${path}?${query}`,
                    method: 'get'
                };
            },
            transformResponse: toClient
        }),
    })
});

export const {
    useGetConsortiumMembersQuery,
} = consortiumMembersApi;

const selectConsortiumMembers = consortiumMembersApi.endpoints.getConsortiumMembers.select({
    // search: '',
    regionsList: [],
    orgTypeList: [],
    orderBy: 'asc',
    pageSize: 20,
    page: 1
});

// TODO: r/ to strapi dict
export const selectEduTypes = createSelector(
    selectConsortiumMembers,
    (consortiumMembers) => {
        if (!consortiumMembers?.data) return [];

        const { list } = consortiumMembers.data;

        // fetch DISTINCT education types
        return Array.from(new Set(list.map((item: any) => item.organizationLevel)))
            .map((item) => ({
                title: item,
                key: item
            }));
    }
);
