import qs from 'qs';
import { monthYear } from 'utils/date';
import { genericApi } from 'store/generic.service';
import type { DigestList, PaginationProps } from './Digest.types';


export const digestApi: any = genericApi.injectEndpoints({
    endpoints: (build) => ({
        getDigestList: build.query<DigestList, PaginationProps>({
            query: ({ page, pageSize }) => {
                const query = qs.stringify({
                    sort: ['date:desc'],
                    pagination: { page, pageSize },
                    populate: ['file', 'logo'],
                });

                return {
                    url: `digests?${query}`,
                    method: 'get'
                };
            },
            transformResponse: (response: any, meta, arg) => {
                const { page, pageSize } = arg;
                const pagination = response?.meta?.pagination || {};
                const total = response?.meta?.pagination.total || 0;

                const list = response?.data?.map((item: any, index: number) => ({
                    id: item.id,
                    title: item.attributes.title,
                    date: monthYear(item.attributes.date),
                    file: {
                        id: item.attributes.file?.data?.id,
                        ...item.attributes.file?.data?.attributes,
                    },
                    logo: {
                        id: item.attributes.logo?.data?.id,
                        ...item.attributes.logo?.data?.attributes,
                    },
                    pos: (total - ((page - 1) * pageSize)) - index,
                })) || [];

                return {
                    list,
                    pagination,
                };
            }
        }),
        getDigestHeader: build.query<any, void>({
            query: () => 'digest-header',
            transformResponse: (response: any) => {
                const {
                    title, columnOne, columnTwo,
                } = response.data.attributes;

                return {
                    title, columnOne, columnTwo,
                };
            }
        })
    })
});

export const {
    useGetDigestListQuery, useGetDigestHeaderQuery
} = digestApi;
