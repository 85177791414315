import React from 'react';
import bem from 'easy-bem';
import { useSelector } from 'store/use-selector';
import Button from 'components/button';
import { PageMobileCorner } from 'components/page-mobile-corner';
import {
    ReactComponent as TopImage
} from 'assets/images/about-mobile-top.svg';
import {
    getAboutHeader,
    getConsortiumHeader,
    getEducationHeader,
    getProfstandardsHeader,
    getWorkingGroupsHeader,
} from './PageHeader.slice';
import type { PageHeaderProps } from './PageHeader.types';
import './style.less';


const PageHeader = (props: PageHeaderProps) => {
    const b = bem('page-header');
    const isMobile = useSelector((state) => state.common.isMobile);

    const {
        title,
        motto,
        actionImgUrl,
        actionTitle,
        showBanner = false,
        actionType = 'link',
        onAction = () => {}
    } = props;

    return (
        <div className={b()}>
            <div className={b('title-wrapper')}>
                <div className={b('title')}>
                    { title }
                </div>
                <div className={b('motto')}>
                    { motto }
                </div>
            </div>
            <div className={b('action-wrapper')}>
                {actionTitle && (
                    <Button type={actionType} onClick={onAction} className={actionType}>
                        <img src={actionImgUrl} alt="" />
                        <span>{ actionTitle }</span>
                    </Button>
                )}
            </div>
            {showBanner && <img className="page-banner" src="/images/page-banner.png" alt="" />}
            {isMobile && <PageMobileCorner />}
        </div>
    );
};

export default PageHeader;

const PageHeaderAboutTop = () => (
    <div className="page-header-top">
        <div className="page-header-top__title">
            Единая образовательная платформа
        </div>
        <div className="page-header-top__icon">
            <TopImage />
        </div>
    </div>
);

const PageHeaderAbout = ({ onApply }: any) => {
    const isMobile = useSelector((state) => state.common.isMobile);
    const header = useSelector(getAboutHeader);

    return (
        <>
            {isMobile && (
                <PageHeaderAboutTop />
            )}
            <PageHeader
                title={header?.title}
                motto={header?.subTitle}
                actionTitle={!isMobile && header.buttonText}
                actionType="primary"
                onAction={onApply}
            />
        </>
    );
};

const PageHeaderConsortium = () => {
    const isMobile = useSelector((state) => state.common.isMobile);
    const header = useSelector(getConsortiumHeader);

    return (
        <PageHeader
            title={header?.title}
            motto={!isMobile && header?.subTitle}
            showBanner
        />
    );
};

const PageHeaderEducation = () => {
    const isMobile = useSelector((state) => state.common.isMobile);
    const header = useSelector(getEducationHeader);

    return (
        <PageHeader
            title={header?.title}
            motto={!isMobile && header?.subTitle}
            showBanner
        />
    );
};

const PageHeaderProfstandards = () => {
    const isMobile = useSelector((state) => state.common.isMobile);
    const header = useSelector(getProfstandardsHeader);

    return (
        <PageHeader
            title={header?.title}
            motto={!isMobile && header?.subTitle}
            showBanner
        />
    );
};

const PageHeaderWorkingGroups = () => {
    const isMobile = useSelector((state) => state.common.isMobile);
    const header = useSelector(getWorkingGroupsHeader);

    return (
        <PageHeader
            title={header?.title}
            motto={!isMobile && header?.subTitle}
            showBanner
        />
    );
};

export {
    PageHeaderAbout,
    PageHeaderConsortium,
    PageHeaderEducation,
    PageHeaderProfstandards,
    PageHeaderWorkingGroups,
};
