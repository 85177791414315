import qs from 'qs';
import { createSelector } from '@reduxjs/toolkit';
import { genericApi } from 'store/generic.service';
// import type { NewsItem } from './types';

const PAGE_SIZE = 50;
const PAGE = 0;

export const newsBlockApi:any = genericApi.injectEndpoints({
    endpoints: (builder) => ({
        getNews: builder.query<any, void>({
            query: () => {
                const query = qs.stringify({
                    sort: ['PubDate:desc'],
                    pagination: { pageSize: PAGE_SIZE, page: PAGE },
                    populate: ['Images']
                }, { encodeValuesOnly: true });

                return {
                    url: `news?${query}`,
                    method: 'get'
                };
            },
            transformResponse: (response: { data: any }) => {
                const { data: list } = response;

                return list.map((record: any) => {
                    const { attributes: item } = record;
                    
                    return {
                        id: record.id,
                        title: item.Title,
                        preview: item.Preview,
                        description: item.Description,
                        turboContent: item.TurboContent,
                        pubDate: item.PubDate,
                        subTitle: item.SubTitle,
                        links: item.Links,
                        image: item.Images.data?.id ? {
                            id: item.Images.data.id,
                            ...item.Images.data.attributes
                        } : null,
                        createdAt: item.createdAt,
                        updatedAt: item.updatedAt,
                        publishedAt: item.publishedAt,
                    };
                });
            }
        })
    })
});

export const { useGetNewsQuery } = newsBlockApi;

export const selectNewsResult = newsBlockApi.endpoints.getNews.select();

const emptyNews: Array<void> = [];

export const selectAllNews = createSelector(
    selectNewsResult,
    (newsResult) => newsResult?.data ?? emptyNews
);
