import React, { Component, ErrorInfo, ReactElement } from 'react';
import bem from 'easy-bem';

import Title from 'components/feature-title';

import './style.less';


interface Props {
    componentTitle: string,
    children: ReactElement
}

interface State {
    hasError: boolean,
    message?: string
}

class ErrorBoundaryLine extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasError: false,
            message: 'Контент временно недоступен. Мы работаем над этим.'
        };
    }

    public static getDerivedStateFromError(): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // this.setState({ error: true });
        console.error('Uncaught error: ', error, errorInfo);
    }

    public render() {
        const b = bem('error-boundary-line');
        const { componentTitle, children } = this.props;
        const { hasError, message } = this.state;

        if (hasError) {
            return (
                <div className={b()}>
                    <div className={b('info')}>
                        {/* <div className={b('title')}>{componentTitle}</div> */}
                        <Title text={componentTitle} />
                        <div className={b('message')}>{message}</div>
                    </div>
                    <div className={b('image')}>
                        <img src="/images/errors/504.png" alt="error-504" />
                    </div>
                </div>
            );
        }

        return children;
    }
}

export default ErrorBoundaryLine;
