import React from 'react';
import { useSelector } from 'store/use-selector';
import { getYearTarget } from 'store/cms.slice';
import TaskItem from './YearTargetsItem';
import './style.less';


const YearTargets = () => {
    const yearTarget = useSelector(getYearTarget);

    return (
        <div className="year-targets wrapper">
            <div className="grid">
                <div className="title">
                    {yearTarget.title}
                </div>
                <div className="list">
                    {
                        Array.isArray(yearTarget.list)
                        && yearTarget.list.map((item: any, idx: any) => (
                            <TaskItem
                                id={idx}
                                title={item.title}
                                linkUrl={item.linkUrl}
                                linkText={item.linkText}
                                description={item.description}
                                data={item.data}
                                dataText={item.dataText}
                                image={item.image}
                                key={item.id}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default YearTargets;
