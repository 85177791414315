import React from 'react';
import type { PriorInd } from 'store/types-legacy';

import { ReactComponent as MonitorIcon } from 'assets/icons/monitor.svg';
import { ReactComponent as FlashIcon } from 'assets/icons/flash.svg';
import { ReactComponent as MortarboardIcon } from 'assets/icons/mortarboard.svg';
import { ReactComponent as PodiumIcon } from 'assets/icons/podium.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/image.svg';
import { ReactComponent as ReduceIcon } from 'assets/icons/reduce.svg';
import { ReactComponent as MedicalKitIcon } from 'assets/icons/medical-kit.svg';
import { ReactComponent as CarIcon } from 'assets/icons/car.svg';
import { ReactComponent as ToolIcon } from 'assets/icons/tool.svg';
import { ReactComponent as ConicalFlaskIcon } from 'assets/icons/conical-flask.svg';
import { ReactComponent as BoltIcon } from 'assets/icons/bolt.svg';


const getIcon = (iconName: string) => {
    switch (iconName) {
        case 'monitor': return <MonitorIcon />;
        case 'flash': return <FlashIcon />;
        case 'mortarboard': return <MortarboardIcon />;
        case 'podium': return <PodiumIcon />;
        case 'image': return <ImageIcon />;
        case 'reduce': return <ReduceIcon />;
        case 'medical-kit': return <MedicalKitIcon />;
        case 'car': return <CarIcon />;
        case 'tool': return <ToolIcon />;
        case 'conical-flask': return <ConicalFlaskIcon />;
        case 'bolt': return <BoltIcon />;
        default: return null;
    }
};

const PriorityIndustriesItem = ({ icon, title }: PriorInd) => (
    <div className="h-item">
        {getIcon(icon)}
        {title}
    </div>
);

export default PriorityIndustriesItem;
