import qs from 'qs';
import { createSelector } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API } from 'utils/request';


type ReturnProps = any[]

type Params = void;

const PAGE_SIZE = 20;
const PAGE = 0;

export const feedbackListenersApi = createApi({
    reducerPath: 'feedbackListenersApi',
    baseQuery: fetchBaseQuery({ baseUrl: API }),
    endpoints: (builder) => ({
        getFeedbackList: builder.query<ReturnProps, Params>({
            query: () => {
                const query = qs.stringify({
                    pagination: { pageSize: PAGE_SIZE, page: PAGE },
                });

                return {
                    url: `education-feedbacks?${query}`,
                    method: 'get'
                };
            },
            transformResponse: (response: { data: any }) => {
                const { data: list } = response;

                return list.map((record: any) => {
                    const { attributes } = record;

                    return {
                        id: record.id,
                        ...attributes
                    };
                });
            }
        })
    })
});

export const { useGetFeedbackListQuery } = feedbackListenersApi;

export const selectFeedbackResult = feedbackListenersApi.endpoints.getFeedbackList.select();

export const selectAllFeedback = createSelector(
    selectFeedbackResult,
    (feedbackRedult) => feedbackRedult?.data ?? []
);
