import React from 'react';
import { useSelector } from 'store/use-selector';
import { getBannerStats } from 'store/cms.slice';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';

import './style.less';


const BannerStatsCards = () => {
    const list = useSelector(getBannerStats);

    return (
        <div className="banner-stats-cards">
            <div className="grid">
                {
                    Array.isArray(list)
                    && list.map((item) => (
                        <div
                            className={`grid-item wrapper ${item.image}`}
                            key={item.id}
                        >
                            <div className="title">
                                {item.title}
                            </div>
                            <div className="subtitle">
                                {item.data}
                            </div>
                            <div className="description">
                                {item.text}
                            </div>
                            <Link
                                className="banner-stats-cards__link"
                                to={item.link}
                            >
                                {item.buttonText}
                                {' '}
                                <ArrowRight />
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default BannerStatsCards;
