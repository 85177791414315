import React from 'react';
import { useSelector } from 'store/use-selector';
import { getConsortiumEduOrgs } from 'store/cms.slice';
import { useHistory } from 'react-router-dom';
import Button from 'components/button';
import './style.less';


const ConsortiumEducationalOrgs = () => {
    const history = useHistory();
    const isMobile = useSelector((state) => state.common.isMobile);
    const consortiumEduOrgs = useSelector(getConsortiumEduOrgs);

    return (
        <div className="consortium-educational-orgs wrapper">
            <div>
                <div className="title">
                    {consortiumEduOrgs.title}
                </div>
                <div className="description">
                    {consortiumEduOrgs.description}
                </div>
                {!isMobile && (
                    <Button
                        type="primary"
                        onClick={() => history.push('/consortium')}
                    >
                        {consortiumEduOrgs.submitText}
                    </Button>
                )}
            </div>
            <div className="stats">
                {Array.isArray(consortiumEduOrgs.list)
                && consortiumEduOrgs.list.map((item: any) => (
                    <div className="stat-item" key={item.id}>
                        <div className="data">
                            {item.data}
                        </div>
                        <div className="label">
                            {item.title}
                        </div>
                    </div>
                ))}
            </div>
            {isMobile && (
                <Button type="primary" onClick={() => history.push('/consortium')}>
                    {consortiumEduOrgs.submitText}
                </Button>
            )}
        </div>
    );
};

export default ConsortiumEducationalOrgs;
