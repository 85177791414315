import React, {
    Suspense, lazy, useRef, useEffect
} from 'react';
import { Switch, Route } from 'react-router-dom';
import bem from 'easy-bem';
import { useDispatch } from 'react-redux';
import { useMedia } from 'react-use';
import { commonSlice } from 'store/common.slice';
import { Header, Footer } from '@unione-pro/unione.commons.webpack';
import SubMenu from 'components/nav-sub-menu';
import ScrollToTop from 'components/scroll-to-top';
import ErrorBoundaryPage from 'components/error-boundary-page';
import PageHeaderSkeleton from 'components/page-header-skeleton';
import { ContactUsSafe } from 'features/contact-us';
import './style.less';

import About from 'pages/about/About';


const Consortium = lazy(() => import('pages/consortium/Consortium'));
const Education = lazy(() => import('pages/education/Education'));
const Actualization = lazy(() => import('pages/actualization/Actualization'));
const WorkingGroups = lazy(() => import('pages/industry-working-group/IndustryWorkingGroups'));
const Information = lazy(() => import('pages/information/Information'));


const MENU_MODE = process.env.REACT_APP_HEADER_MENU_MODE;
const CHAT_DISABLED = !(process.env.REACT_APP_CHAT_ENABLED === 'true');

const PageWrapper = () => {
    const b = bem('page-wrapper');
    const contentRef = useRef();
    const dispatch = useDispatch();
    const isMobile = useMedia('(max-width: 756px)');

    useEffect(() => {
        dispatch(commonSlice.actions.setMobileState(isMobile));
    }, [isMobile, dispatch]);

    return (
        <div className={b()}>
            <Header
                // @ts-ignore
                env={MENU_MODE}
                disableChat={CHAT_DISABLED}
                chatPosition={{
                    right: -140,
                    // bottom: 94
                }}
            />
            <ErrorBoundaryPage>
                <SubMenu />
                <div className={b('container')}>
                    <div
                        // @ts-ignore
                        ref={contentRef}
                        className={b('content')}
                    >
                        <ScrollToTop
                            // @ts-ignore
                            body={contentRef}
                        />
                        <Suspense fallback={<PageHeaderSkeleton />}>
                            <Switch>
                                <Route exact path={['/', '/about']} component={About} />
                                <Route exact path="/consortium" component={Consortium} />
                                <Route exact path="/education" component={Education} />
                                <Route exact path="/actualization" component={Actualization} />
                                <Route exact path="/working-groups" component={WorkingGroups} />
                                <Route exact path="/information" component={Information} />
                                <Route component={About} key="fallBack" />
                            </Switch>
                        </Suspense>
                        <ContactUsSafe />
                        <Footer />
                    </div>
                </div>
            </ErrorBoundaryPage>
        </div>
    );
};

export default PageWrapper;
