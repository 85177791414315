import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import i18n from 'i18n/i18n';
import store from 'store/store';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundaryPage from 'components/error-boundary-page';
import { Wrapper } from '@unione-pro/unione.commons.webpack';
import PageWrapper from 'components/page-wrapper';

import 'antd/dist/antd.css';
import './style.less';


const App = () => (
    <Wrapper>
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <BrowserRouter>
                    <ErrorBoundaryPage>
                        <PageWrapper />
                    </ErrorBoundaryPage>
                </BrowserRouter>
            </Provider>
        </I18nextProvider>
    </Wrapper>
);

export default App;
