import React from 'react';
import { useSelector } from 'store/use-selector';
import { getPrioritizedIndustries } from 'store/cms.slice';
import PriorityIndustriesItem from './PriorityIndustriesItem';
import './style.less';


const PriorityIndustries = () => {
    const entity = useSelector(getPrioritizedIndustries);

    return (
        <div className="priority-industries wrapper">
            <div className="title">
                {entity.title}
            </div>
            <div className="description">
                {entity.description}
            </div>
            <div className="industries-list">
                {
                    Array.isArray(entity.list)
                    && entity.list.map((data: any) => (
                        <PriorityIndustriesItem
                            icon={data.icon}
                            title={data.title}
                            key={data.title}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default PriorityIndustries;
