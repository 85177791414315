// @ts-nocheck
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { genericApi } from 'store/generic.service';
import { FILE_API } from 'utils/request';
import { singleToClient } from 'utils/converters';
// import type { MailProps } from './types';
import type { ContactUs } from './types';


export const contactUsStrapiApi: any = genericApi.injectEndpoints({
    endpoints: (build) => ({
        getContactUsForm: build.query<ContactUs, void>({
            query: () => 'contact-us',
            transformResponse: (response: any) => {
                // TODO: lower strapi uppercase fields
                const payload = singleToClient(response);
                const { typeList } = payload;
                const newTypeList = [...typeList.filter((item: string) => item.charAt(0) !== '-')];
                
                return {
                    ...payload,
                    typeList: newTypeList,
                };
            }
        }),
        // TODO: refactor to genericApi, mailer plugin has no /api/ infront!
        // sendFeedback: build.mutation<any, any>({
        //     query: (body) => ({
        //         url: '/mailer/send-mail',
        //         method: 'POST',
        //         body
        //     }),
        //     transformResponse: (response: { data: any }) => response?.data,
        // })
    })
});

export const contactUsApi = createApi({
    reducerPath: 'contactUsApi',
    baseQuery: fetchBaseQuery({ baseUrl: FILE_API }), // FILE_API - without prefix /api/
    endpoints: (build) => ({
        sendFeedback: build.mutation<any, any>({
            query: (body) => ({
                url: '/mailer/send-mail',
                method: 'POST',
                body
            }),
            transformResponse: (response: { data: any }) => response?.data,
        }),
    })
});


export const { useSendFeedbackMutation } = contactUsApi;
export const { useGetContactUsFormQuery } = contactUsStrapiApi;
