import qs from 'qs';
import { genericApi } from 'store/generic.service';
import { singleToClient, filesToClient } from 'utils/converters';


export const modalConsortiumApplyApi: any = genericApi.injectEndpoints({
    endpoints: (builder) => ({
        getApplyFields: builder.query<any, void>({
            query: () => 'modal-consortium-apply',
            transformResponse: (response: any) => singleToClient(response)
        }),
        getDocs: builder.query<any, void>({
            query: () => {
                const query = qs.stringify({
                    filters: {
                        entity_name: {
                            $eq: 'modal-consortium-apply'
                        }
                    },
                    populate: ['file'],
                });

                return {
                    url: `docs?${query}`,
                    method: 'get'
                };
            },
            transformResponse: (response: any) => filesToClient(response)
        }),
    })
});

export const {
    useGetApplyFieldsQuery,
    useGetDocsQuery,
} = modalConsortiumApplyApi;
