import React, { ReactElement } from 'react';
import { Modal } from 'antd';
import bem from 'easy-bem';

import './style.less';


type ModalProps = {
    title: string | ReactElement | undefined
    visible: boolean
    className: string
    children: React.ReactNode
    closable?: boolean
    footer?: any[] | ReactElement
    onCancel?: any
    destroyOnClose?: boolean
    maskClosable?: boolean
    width?: string | number
    centered?: boolean
    afterClose?: any
}

const OOCModal = (props: ModalProps) => {
    const b = bem('ooc-modal');
    const {
        className: propsClass,
        children,
        closable = false,
        ...rest
    } = props;

    const cn = propsClass ? `${b()} ${propsClass}` : b();

    return (
        <Modal
            className={cn}
            closable={closable}
            {...rest}
        >
            {children}
        </Modal>
    );
};

export default OOCModal;
