import { combineReducers } from 'redux';

import { commonSlice } from 'store/common.slice';
import { cmsApi } from 'store/cms.slice';
import { consortiumMembersApi } from 'features/consortium-members-table/ConsortiumMembersTable.slice';
import { consortiumMembersLogosApi } from 'features/consortium-members-logos/ConsortiumMembersLogos.slice';
import { digestApi } from 'features/digest/Digest.slice';
import { contactUsStrapiApi, contactUsApi } from 'features/contact-us/ContactUs.slice';
import { feedbackDrawerSlice } from 'components/drawer-feedback-listeners/DrawerFeedbackListeners.slice';
import { feedbackListenersApi } from 'features/feedback-listeners/FeedbackListeners.slice';
import { mainEventsApi } from 'features/main-events/MainEvents.slice';
import { modalConsortiumApplyApi } from 'features/modal-consortium-apply/ModalConsortiumApply.slice';
import { newsBlockApi } from 'features/news-block/NewsBlock.slice';
import { regionsApi } from 'features/consortium-members-table/TableFilterRegion.slice';
// import { workingGroupsApi } from 'features/working-groups/WorkingGroups.slice';
import { workingGroupsApi } from 'features/working-groups-v2/WorkingGroups.slice';


export default combineReducers({
    [commonSlice.name]: commonSlice.reducer,
    [cmsApi.name]: cmsApi.reducer,
    [consortiumMembersApi.reducerPath]: consortiumMembersApi.reducer,
    [consortiumMembersLogosApi.reducerPath]: consortiumMembersLogosApi.reducer,
    [contactUsStrapiApi.reducerPath]: contactUsStrapiApi.reducer,
    [contactUsApi.reducerPath]: contactUsApi.reducer,
    [digestApi.reducerPath]: digestApi.reducer,
    [feedbackDrawerSlice.name]: feedbackDrawerSlice.reducer,
    [feedbackListenersApi.reducerPath]: feedbackListenersApi.reducer,
    [mainEventsApi.reducerPath]: mainEventsApi.reducer,
    [modalConsortiumApplyApi.reducerPath]: modalConsortiumApplyApi.reducer,
    [newsBlockApi.reducerPath]: newsBlockApi.reducer,
    [regionsApi.reducerPath]: regionsApi.reducer,
    [workingGroupsApi.reducerPath]: workingGroupsApi.reducer,
});
