import React from 'react';
import { Form, Input } from 'antd';
import MaskedInput from 'antd-mask-input';
import bem from 'easy-bem';

import './style.less';


type OOCInputProps = {
    className?: string
    textarea?: boolean
    type?: string
    label?: string
    withPassword?: boolean
    name?: string
    required?: boolean
    validateStatus?: any
    help?: string
    mask?: string
    rules?: any[]
    bordered?: boolean
    value?: string
    autoSize?: boolean
    placeholder?: string
    size?: any
    rows?: any
    disabled?: boolean
    maskOptions?: {
        placeholderChar: string
    }
}

const OOCInput: React.FC<OOCInputProps> = (props) => {
    const b = bem('ooc-input');
    const {
        className: classNameProp,
        textarea = false,
        type = undefined,
        label = undefined,
        withPassword = false,
        name,
        required = false,
        validateStatus,
        help,
        mask,
        rules = [],
        maskOptions,
        ...rest
    } = props;

    const cn = classNameProp ? `${classNameProp} ${b()}` : b();

    if (textarea && label) {
        return (
            <div className={`${cn} ${b('textarea')}`}>
                <span>{label}</span>
                <Input.TextArea {...rest} />
            </div>
        );
    }

    if (textarea) {
        return (
            <Input.TextArea className={`${cn} ${b('textarea')}`} {...rest} />
        );
    }

    if (type === 'form' && label && !mask) {
        return (
            <div className={`${cn} ${b('form-input')} `}>
                <Form.Item
                    name={name}
                    label={label}
                    required={required}
                    validateStatus={validateStatus}
                    help={help}
                    rules={rules}
                >
                    {
                        withPassword
                            ? <Input {...rest} type="password" />
                            : <Input {...rest} />
                    }
                </Form.Item>
            </div>
        );
    }

    if (type === 'form' && label && mask) {
        return (
            <Form.Item
                name={name}
                label={label}
                required={required}
                validateStatus={validateStatus}
                help={help}
                rules={rules}
            >
                <MaskedInput
                    mask={mask}
                    maskOptions={maskOptions}
                    {...rest}
                    className="ooc-input ooc-input__mask"
                />
            </Form.Item>
        );
    }

    if (label) {
        return (
            <div className={cn}>
                <span className="label">{label}</span>
                <Input {...rest} />
            </div>
        );
    }

    return (
        <Input className={cn} {...rest} />
    );
};

export default OOCInput;
