import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API } from 'utils/request';


export const genericApi = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: API }),
    endpoints: () => ({})
});

export type RequestProps = {
    pageSize: number
    page: number
}
