import React from 'react';
import bem from 'easy-bem';

import './style.less';


type Props = {
    text: string
}

const FeatureTitle = (props: Props) => {
    const b = bem('feature-title');
    const {
        text,
    } = props;

    return (
        <div className={b()}>
            {text}
        </div>
    );
};

export default FeatureTitle;
