import React from 'react';
import bem from 'easy-bem';
import type { MainEvent } from '../types';
import './style.less';


const MainEventsItem = (props: MainEvent) => {
    const b = bem('main-events-item');
    const {
        url,
        image,
        id
    } = props;

    return (
        <a
            className={b()}
            href={url}
            target={id}
        >
            <img
                className={b('banner')}
                src={image}
                role="banner"
                alt="banner"
            />
        </a>
    );
};

export default MainEventsItem;
