import React, { Component, ReactNode } from 'react';
// import { withTranslation } from 'react-i18next';
// import { withRouter } from 'react-router';
// import { BrowserRouter as Router } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import bem from 'easy-bem';
// import { Result, Collapse } from 'antd';
// import Button from '../button';

import './style.less';


// const { Panel } = Collapse;

interface Props {
    // componentTitle: string,
    children: ReactNode
}

interface State {
    hasError: boolean,
    message?: string
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasError: false,
            message: 'Контент временно недоступен. Мы работаем над этим.'
        };
    }

    componentDidCatch() {
        // console.log("ErrorBoundary ~ error, errorInfo", error, errorInfo);
        this.setState({ hasError: true });
    }

    render() {
        const b = bem('error-boundary');
        const { children } = this.props;
        const { hasError, message } = this.state;

        if (hasError) {
            return (
                <div className={b()}>
                    <div className={b('box')}>
                        <img src="/images/errors/504.png" alt="error-504" />
                        <div className={b('text')}>
                            {/* Контент временно недоступен. Мы работаем над этим. */}
                            {message}
                        </div>
                    </div>
                </div>
            );
        }

        return children;
    }
}

// @ts-ignore
export default withRouter(ErrorBoundary);
