import React from 'react';
import { Skeleton } from 'antd';
import './style.less';


const PageHeaderSkeleton = () => (
    <div className="page-header-skeleton">
        <Skeleton active />
    </div>
);

export default PageHeaderSkeleton;
