/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { MouseEventHandler } from 'react';
import { Progress } from 'antd';

import { ReactComponent as LeftGrayArrow } from 'assets/icons/arrow-left-single-gray.svg';
import { ReactComponent as RightBlueArrow } from 'assets/icons/arrow-right-single-blue.svg';


type Props = {
    currentPage: number
    totalPages: number
    onPrevPage: MouseEventHandler<HTMLSpanElement>
    onNextPage: MouseEventHandler<HTMLSpanElement>
}

export default function DigestFooter({
    currentPage, totalPages, onPrevPage, onNextPage
}: Props) {
    const firstPage = currentPage === 1;
    const nextToFirst = currentPage > 1;
    const nextToLast = currentPage < totalPages;
    const lastPage = currentPage === totalPages;
    const progress = (currentPage / totalPages) * 100;

    return (
        <div className="digest-footer">
            <div className="progress">
                <div className="count">
                    <span className="current">
                        {currentPage}
                    </span>
                    <span className="separator">
                        /
                    </span>
                    <span className="total">
                        {totalPages}
                    </span>
                </div>
                <div className="progress-bar">
                    <Progress
                        percent={progress}
                        showInfo={false}
                    />
                </div>
            </div>
            <div className="buttons">
                <span>
                    {
                        firstPage && <LeftGrayArrow />
                    }
                </span>
                <span onClick={onPrevPage}>
                    {
                        nextToFirst && <RightBlueArrow className="rotated" />
                    }
                </span>
                <span onClick={onNextPage}>
                    {
                        nextToLast && <RightBlueArrow />
                    }
                </span>
                <span>
                    {
                        lastPage && <LeftGrayArrow className="rotated" />
                    }
                </span>
            </div>
        </div>
    );
}
