import qs from 'qs';
import { createSelector } from '@reduxjs/toolkit';
import { genericApi, RequestProps } from 'store/generic.service';
import { collectionToClient, StrapiResponse } from 'utils/converters';
import type { Person } from './types';


const groupTemplate = [
    'Руководитель рабочей группы',
    'Соруководитель рабочей группы',
    'Ответственный секретарь',
    'ФОИВ',
    'Организация подведомственная ФОИВ',
    'РОИВ',
    'ОМСУ',
    'ВУЗ',
    'СПО',
    'ДПО',
    'СПК',
    'ФУМО',
    'ИТ-компания',
    'Индустриальная компания',
    'АНО "Цифровая экономика"',
    'АНО ВО "Университет Иннополис"',
    'Иной эксперт',
];

const sortFunct = (a: any, b: any) => groupTemplate.indexOf(a) - groupTemplate.indexOf(b);

type RequestPropsLocal = RequestProps & { category: string, group?: string };

export const workingGroupsApi:any = genericApi.injectEndpoints({
    endpoints: (builder) => ({
        getWorkingGroups: builder.query<any, void>({
            query: () => 'work-groups',
            transformResponse: (response: StrapiResponse) => (
                collectionToClient(response)?.list || []
            )
        }),
        getCategories: builder.query<any, void>({
            query: () => 'work-group-categories',
            transformResponse: (response: StrapiResponse) => (
                collectionToClient(response)?.list || []
            )
        }),
        getGroups: builder.query<any, RequestPropsLocal>({
            query: ({ category }) => {
                const query = qs.stringify({
                    filters: {
                        category: {
                            $eq: category
                        }
                    },
                    fields: ['group'],
                    pagination: { pageSize: 60 },
                });

                return {
                    url: `work-groups?${query}`,
                    mathod: 'get'
                };
            },
            transformResponse: (response: StrapiResponse) => {
                const { list } = collectionToClient(response);
                const plainGroups = list.map((item: any) => item.group);
                const distinctGroups = new Set(plainGroups);
                const sortedGroups = Array.from(distinctGroups).sort(sortFunct);

                return sortedGroups;
            }
        }),
        getPersons: builder.query<Person[], RequestPropsLocal>({
            query: ({ category, group }) => {
                const query = qs.stringify({
                    filters: {
                        category: { $eq: category },
                        group: { $eq: group },
                    },
                    pagination: { pageSize: 60 },
                    sort: ['id'],
                });

                return {
                    url: `work-groups?${query}`,
                    mathod: 'get'
                };
            },
            transformResponse: (response: StrapiResponse): any[] => (
                collectionToClient(response)?.list || []
            )
        })
    })
});

export const {
    useGetWorkingGroupsQuery,
    useGetCategoriesQuery,
    useGetGroupsQuery,
    useGetPersonsQuery,
} = workingGroupsApi;

const api = workingGroupsApi;
export const selectWorkingGroups = api.endpoints.getWorkingGroups.select();
export const selectCategorties = api.endpoints.getCategories.select();
// export const selectGroups = (category: string) => api.endpoints.getGroups.select({ category });
// export const selectRawGroups = api.endpoints.getGroups.select({ category: 'Городское хозяйство' }); // ok

export const selectAllWorkingGroups = createSelector(
    selectWorkingGroups,
    (workingGroups) => workingGroups?.data ?? []
);

export const selectAllCategorties = createSelector(
    selectCategorties,
    (categories) => categories?.data ?? []
);
