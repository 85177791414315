/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


type CommonState = {
    isMobile: boolean
}

const initialState:CommonState = { isMobile: false };

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setMobileState(state: CommonState, action: PayloadAction<boolean>) {
            state.isMobile = action.payload;
        }
    }
});

export const { setMobileState } = commonSlice.actions;
