import React from 'react';
import bem from 'easy-bem';
import { FILE_API } from 'utils/request';
import Button from 'components/button';
import Modal from 'components/modal';
import {
    useGetApplyFieldsQuery,
    useGetDocsQuery,
} from './ModalConsortiumApply.slice';

import './style.less';


type BPProps = {
    list: any[],
    docs: any
}

const BulletPoints = (props: BPProps) => {
    const { list, docs } = props;

    if (!list) return null;

    const downloadURI = (url: string, filename: string) => {
        fetch(FILE_API + url)
            .then((response) => response.blob())
            .then((blob) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            })
            .catch(console.error);
    };

    return (
        <ul className="bulletpoints">
            {
                list.map((item) => (
                    <li key={item.number} className="bulletpoint">
                        <div className="bulletpoint__wrapper">
                            <span className="bulletpoint__title">
                                {item.title}
                            </span>
                            <span className="bulletpoint__content">
                                {item.content}
                                {item.href && (
                                    <>
                                        &nbsp;
                                        {/* works only with relative host files */}
                                        {/* <a href={item.href} download={item.fileName}>{item.linkText}</a> */}
                                        <Button
                                            type="link"
                                            onClick={() => downloadURI(
                                                docs[item.file_name_id]?.url,
                                                item.fileName
                                            )}
                                        >
                                            {item.linkText}
                                        </Button>
                                    </>
                                )}
                            </span>
                        </div>
                    </li>
                ))
            }
        </ul>
    );
};


type MCAProps = {
    visible: boolean,
    onClose: Function
}

const ModalConsortiumApply = ({ visible, onClose }: MCAProps) => {
    const { data: consortiumApply } = useGetApplyFieldsQuery();
    const { data: docs } = useGetDocsQuery();
    const b = bem('modal-consortium-apply');

    const docsObj: any = {};

    // TODO: refactor to .slice selector
    if (Array.isArray(docs)) {
        docs.forEach((item: any) => {
            if (item.file_name_id) {
                docsObj[item.file_name_id] = item.file;
            }
        });
    }

    return (
        <Modal
            footer={[
                <Button
                    onClick={onClose}
                    type="link"
                    key="close"
                >
                    Закрыть
                </Button>,
            ]}
            title={consortiumApply?.header}
            closable={false}
            className={b()}
            visible={visible}
            destroyOnClose
            onCancel={onClose}
            maskClosable
            width="100%"
            centered
        >
            <div className={b('header')}>
                {consortiumApply?.subHeader}
            </div>
            <div className={b('body')}>
                <BulletPoints list={consortiumApply?.list} docs={docsObj} />
            </div>
        </Modal>
    );
};

export default ModalConsortiumApply;
