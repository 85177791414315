import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';


dayjs.locale('ru');
dayjs.extend(customParseFormat);

export const today = dayjs();

export const formatDate = (rawDate: Date) => {
    if (!rawDate) return '';

    return dayjs(rawDate).format('DD.MM.YYYY');
};

export const formatDateToISO = (date: string) => dayjs(date).toISOString();

export const formatDateWithMonth = (rawDate: string) => {
    if (!rawDate) return '';

    return dayjs(rawDate).format('DD MMMM YYYY');
};

export const formatToMon3 = (date: string) => dayjs(date).format('DD MMM YYYY');

type delimiterTypes = 'by-month' | 'by-day' | null;

export const formatDateWithMonthLocale = (rawDate: Date, delimiter?: delimiterTypes) => {
    if (!rawDate) return '';

    const parsedDate = dayjs(rawDate, 'DD.MM.YYYY');

    if (delimiter === 'by-month') {
        return parsedDate.format('DD MMMM');
    }

    if (delimiter === 'by-day') {
        return parsedDate.format('DD');
    }

    return parsedDate.format('DD MMMM YYYY');
};

const findMonthDifference = (startDate: Date, endDate: Date): number => {
    const start = dayjs(startDate, 'DD.MM.YYYY');
    const end = dayjs(endDate, 'DD.MM.YYYY');

    return dayjs(end).diff(start, 'month');
};

export const getCourseBannerDate = (startDate: Date[], endDate: Date[], fallbackText: string): string => {
    if (!startDate || !endDate) return fallbackText;

    const [start] = startDate;
    const [end] = endDate;

    const diff = findMonthDifference(start, end);

    const from = diff > 0
        ? formatDateWithMonthLocale(start, 'by-month')
        : formatDateWithMonthLocale(start, 'by-day');

    const to = formatDateWithMonthLocale(end);

    return `${from} - ${to}`;
};

export const monthYear = (date: string) => {
    const parsedDate = dayjs(date, 'YYYY-MM-DD');

    return parsedDate.format('MMMM YYYY');
};
