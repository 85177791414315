import React, { useState } from 'react';
import { useSelector } from 'store/use-selector';
import { getProjectTarget } from 'store/cms.slice';
import { FILE_API } from 'utils/request';
import Button from 'components/button';
import PresentationViewerModal from 'components/presentation-viewer-modal';
import { ReactComponent as DownloadFileIcon } from 'assets/icons/download-file.svg';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import './style.less';


const ProjectTarget = ({ openModalApply }: any) => {
    const [modalVisible, setModalVisible] = useState(false);
    const isMobile = useSelector((state) => state.common.isMobile);
    const projectTarget = useSelector(getProjectTarget);
    const docUrl = projectTarget.presentationModal?.docUrl;
    const docTitle = projectTarget.presentationModal?.title;

    const toggleModal = () => setModalVisible((prev) => !prev);

    return (
        <div className="project-target wrapper">
            <PresentationViewerModal
                url={FILE_API + docUrl}
                visible={modalVisible}
                onClose={toggleModal}
                title={docTitle}
            />
            {!isMobile && (
                <img
                    className="page-banner"
                    src="/images/consortium/page-banner.png"
                    alt=""
                />
            )}
            <div className="grid">
                <div className="grid-content">
                    <div className="title">
                        {projectTarget.title}
                    </div>
                    <div className="description">
                        {projectTarget.description}
                    </div>
                    {isMobile && (
                        <>
                            <LiteYouTubeEmbed
                                id="AbstUX5N_s0"
                                title="Опорный образовательный центр"
                            />
                            <span className="lite-player-banner__wrapper">
                                <img className="lite-player-banner" src="/images/page-banner.png" alt="" />
                            </span>
                        </>

                    )}
                    <div className="stats-list-header">
                        {projectTarget.divisions?.title}
                    </div>
                    <div className="stats-list">
                        <div className="list-item">
                            <span className="item-title">
                                {projectTarget.divisions?.block[0].title}
                            </span>
                            <div className="item-description accent">
                                {projectTarget.divisions?.block[0].subTitle}
                            </div>
                            <div className="item-description">
                                {projectTarget.divisions?.block[0].description}
                            </div>
                        </div>
                        <div className="list-item">
                            <span className="item-title">
                                {projectTarget.divisions?.block[1].title}
                            </span>
                            <div className="item-description accent">
                                {projectTarget.divisions?.block[1].subTitle}
                            </div>
                            <div className="item-description">
                                {projectTarget.divisions?.block[1].description}
                            </div>
                        </div>
                    </div>
                    <div className="action-list">
                        <Button onClick={openModalApply} type="primary">
                            {projectTarget.submitText}
                        </Button>
                        <Button
                            onClick={toggleModal}
                            icon={<DownloadFileIcon width="17px" />}
                            type={isMobile ? 'link' : 'ghost'}
                        >
                            {projectTarget.presentationButtonText}
                        </Button>
                    </div>
                </div>
                {!isMobile && (
                    <div className="grid-player-wrapper">
                        <div className="player">
                            <LiteYouTubeEmbed
                                id="AbstUX5N_s0"
                                title="Опорный образовательный центр"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectTarget;
