/* eslint-disable camelcase */
interface recordProps {
    id: number,
    attributes: any
}

export type StrapiResponse = {
    meta: {
        pagination: {
            page: number,
            pageCount: number,
            pageSize: number,
            total: number,
        }
    },
    data: Array<recordProps>
}

const adaptToClient = (record: recordProps) => ({
    id: record.id,
    ...record.attributes
});

export const collectionToClient = (record: StrapiResponse) => ({
    count: record?.meta?.pagination?.total || 0,
    list: record?.data?.map(adaptToClient) || []
});

// cms
interface singleProps {
    data: {
        attributes: object
    }
}

export const singleToClient = (record: singleProps) => (
    Object.fromEntries(
        Object.entries(record?.data?.attributes)
            .map(
                ([k, v]) => [`${k[0].toLowerCase()}${k?.slice(1)}`, v]
            )
    )
);

type fileProps = {
    id: number,
    attributes: {
        entity_name: string
        file_name_id: string
        file: {
            data: {
                id: number
                attributes: any
            }
        }
    }
}

const adaptFileToClient = (record: fileProps) => {
    try {
        return {
            id: record.id,
            entity_name: record.attributes.entity_name,
            file_name_id: record.attributes.file_name_id,
            file: {
                id: record.attributes.file.data.id,
                ...record.attributes.file.data.attributes,
            }
        };
    } catch (e) {
        console.error('file adapt error', e);
        return {};
    }
};

type filesProps = {
    meta: {
        pagination: {
            page: number,
            pageCount: number,
            pageSize: number,
            total: number,
        }
    },
    data: Array<fileProps>
}

export const filesToClient = (record: filesProps) => (
    record?.data?.map(adaptFileToClient) || []
);
