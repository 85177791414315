import qs from 'qs';
import { genericApi } from 'store/generic.service';
import type { MainEvent } from './types';


type RequestProps = {
    pageSize: number
    page: number
}

export const mainEventsApi:any = genericApi.injectEndpoints({
    endpoints: (builder) => ({
        getMainEvents: builder.query<MainEvent[], RequestProps>({
            query: ({ pageSize, page }) => {
                const query = qs.stringify({
                    sort: ['isFirst:desc'],
                    pagination: { pageSize, page },
                    populate: ['image']
                }, { encodeValuesOnly: true });

                return {
                    url: `main-events?${query}`,
                    method: 'get'
                };
            },
            transformResponse: (response: { data: any }) => {
                const { data: list } = response;

                return list.map((item: any) => {
                    const {
                        name, url, isFirst, image
                    } = item.attributes;

                    return {
                        id: item.id,
                        // ...item.attributes
                        name,
                        url,
                        isFirst,
                        image: {
                            id: image.data.id,
                            ...image.data.attributes,
                        }
                    };
                });
            }
        }),
    }),
    overrideExisting: false
});

export const { useGetMainEventsQuery } = mainEventsApi;
