/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';


type DrawerState = {
    isVisible: boolean
}

const initialState:DrawerState = { isVisible: false };

// TODO: убрать, т.к. похоже это не использовалось
export const feedbackDrawerSlice = createSlice({
    name: 'feedbackDrawer',
    initialState,
    reducers: {
        open(state: any) {
            state.isVisible = true;
        },
        close(state: any) {
            state.isVisible = false;
        },
    }
});

export const { open, close } = feedbackDrawerSlice.actions;
