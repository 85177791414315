import { createSelector } from '@reduxjs/toolkit';
import { genericApi } from './generic.service';


export const cmsApi:any = genericApi.injectEndpoints({
    endpoints: (builder) => ({
        getAboutPage: builder.query<any, void>({
            query: () => 'about',
            transformResponse: (response: { data: any }) => response.data.attributes
        }),
        getConsortiumPage: builder.query<any, void>({
            query: () => 'consortium',
            transformResponse: (response: { data: any }) => response.data.attributes
        }),
        getEducationPage: builder.query<any, void>({
            query: () => 'education',
            transformResponse: (response: { data: any }) => response.data.attributes
        }),
        getProfstandardsPage: builder.query<any, void>({
            query: () => 'profstandard',
            transformResponse: (response: { data: any }) => response.data.attributes
        }),
        getWorkingGroupsPage: builder.query<any, void>({
            query: () => 'industry-working-group',
            transformResponse: (response: { data: any }) => response.data.attributes
        }),
    })
});

export const {
    useGetAboutPageQuery,
    useGetConsortiumPageQuery,
    useGetEducationPageQuery,
    useGetProfstandardsPageQuery,
    useGetWorkingGroupsPageQuery,
} = cmsApi;

export const selectAboutPage = cmsApi.endpoints.getAboutPage.select();
export const selectConsortiumPage = cmsApi.endpoints.getConsortiumPage.select();
export const selectEducationPage = cmsApi.endpoints.getEducationPage.select();
export const selectProfstandsrdsPage = cmsApi.endpoints.getProfstandardsPage.select();
export const selectWorkingGroupsPage = cmsApi.endpoints.getWorkingGroupsPage.select();

// about page
export const getBannerStats = createSelector(
    selectAboutPage,
    (result) => result?.data?.BannerStats ?? []
);

export const getProjectTarget = createSelector(
    selectAboutPage,
    (result) => result?.data?.ProjectTarget ?? {}
);

export const getYearTarget = createSelector(
    selectAboutPage,
    (result) => result?.data?.YearTarget ?? {}
);

export const getWorkingGroups = createSelector(
    selectAboutPage,
    (result) => result?.data?.WorkingGroups ?? {}
);

export const getPrioritizedIndustries = createSelector(
    selectAboutPage,
    (result) => result?.data?.PrioritizedIndustries ?? {}
);

export const getCitationBlock = createSelector(
    selectAboutPage,
    (result) => result?.data?.CitationBlock ?? {}
);

export const getConsortiumEduOrgs = createSelector(
    selectAboutPage,
    (result) => result?.data?.ConsortiumEduOrgs ?? {}
);

// consortium page
export const getConsortiumTarget = createSelector(
    selectConsortiumPage,
    (result) => result?.data?.ConsortiumTarget ?? {}
);

// TODO: remove obsole "list" records from strapi4 structure
export const getConsortiumParticipants = createSelector(
    selectConsortiumPage,
    (result) => result?.data?.ConsortiumParticipants ?? {}
);

export const getPartnerProgramm = createSelector(
    selectConsortiumPage,
    (result) => result?.data?.PartnerProgramm ?? {}
);

export const getPartnerLevels = createSelector(
    selectConsortiumPage,
    (result) => result?.data?.PartnerLevels ?? {}
);

export const getPartnerLevelConditions = createSelector(
    selectConsortiumPage,
    // FIXME: strapi4 type "condiions"
    (result) => result?.data?.PartnerLevelCondiions ?? {}
);

export const getTeacherAndMethodistsEdu = createSelector(
    selectConsortiumPage,
    (result) => result?.data?.TeacherAndMethodistsEdu ?? {}
);

// education page
export const getTarget = createSelector(
    selectEducationPage,
    (result) => result?.data?.Target ?? {}
);

export const getEduProgram = createSelector(
    selectEducationPage,
    (result) => result?.data?.EduProgram ?? {}
);

export const getWhoTeach = createSelector(
    selectEducationPage,
    (result) => result?.data?.WhoTeach ?? {}
);

export const getEduResults = createSelector(
    selectEducationPage,
    (result) => result?.data?.EduResults ?? {}
);

export const getGeography = createSelector(
    selectEducationPage,
    (result) => result?.data?.Geography ?? {}
);

export const getStudentReviews = createSelector(
    selectEducationPage,
    (result) => result?.data?.StudentReviews ?? {}
);

// opop & profstandards / actualization page
export const getPlan = createSelector(
    selectProfstandsrdsPage,
    (result) => result?.data?.Plan ?? {}
);

export const getWGPage = createSelector(
    selectProfstandsrdsPage,
    (result) => result?.data?.WorkingGroups ?? {}
);

export const getActualizationEdu = createSelector(
    selectProfstandsrdsPage,
    (result) => result?.data?.ActualizationEdu ?? {}
);

export const getActualizationProf = createSelector(
    selectProfstandsrdsPage,
    (result) => result?.data?.ActualizationProf ?? {}
);

// industry working group
export const getWGHeader = createSelector(
    selectWorkingGroupsPage,
    (result) => result?.data?.header ?? {}
);

export const getWGGoal = createSelector(
    selectWorkingGroupsPage,
    (result) => result?.data?.goal ?? {}
);

export const getWGComposition = createSelector(
    selectWorkingGroupsPage,
    (result) => result?.data?.composition ?? {}
);

export const getWGData = createSelector(
    selectWorkingGroupsPage,
    (result) => result?.data?.workingGroups ?? {}
);
