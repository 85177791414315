import React from 'react';
import { FILE_API } from 'utils/request';
import type { DigestItem as props } from './Digest.types';


export default function DigestItem({
    title, date, file, logo, pos
}: props) {
    return (
        <div className="digest-item">
            <a
                href={FILE_API + file.url}
                target={file.id}
            >
                <span className="badge">
                    #
                    {pos}
                </span>
                <div className="logo">
                    <img
                        src={FILE_API + logo.url}
                        alt={logo.caption}
                    />
                </div>
                <div className="content">
                    <div className="date">
                        <div>{date}</div>
                    </div>
                    <div className="title">
                        <div>{title}</div>
                    </div>
                </div>
            </a>
        </div>
    );
}
