import { createSelector } from '@reduxjs/toolkit';
// import { newsBlockApi } from 'features/news-guest-widget/NewsBlock.service';
import { selectAllNews } from 'features/news-block/NewsBlock.slice';

// export const selectNewsResult = newsBlockApi.endpoints.getNews.select();

// const emptyNews: Array<void> = [];

// export const selectAllNews = createSelector(
//     selectNewsResult,
//     (newsResult) => newsResult?.data ?? emptyNews
// );

export const selectThreeNews = createSelector(
    selectAllNews,
    (list) => list.slice(0, 3)
);
