import React from 'react';
import { useSelector } from 'store/use-selector';
import { getWorkingGroups } from 'store/cms.slice';
import { Divider } from 'antd';
import './style.less';


const IndustryItem = ({ index, name }: any) => (
    <div className="industry-item">
        <span className="industry-item__count">
            {`0${index + 1}.`}
        </span>
        <span className="industry-item__label">
            {name}
        </span>
    </div>
);

const WorkingIndustryGroups = () => {
    const isMobile = useSelector((state) => state.common.isMobile);
    const workingGroups = useSelector(getWorkingGroups);

    return (
        <div className="working-industry-groups wrapper">
            <div className="title">
                {workingGroups.title}
            </div>
            <div className="grid">
                <div>
                    <span className="description">
                        {workingGroups.descriptionOne}
                    </span>
                    <br />
                    <br />
                    <span className="description">
                        {workingGroups.descriptionTwo}
                    </span>
                </div>
                {isMobile && <Divider className="divider" />}
                <div>
                    <div className="subtitle">
                        {workingGroups.contains?.title}
                    </div>
                    <div className="group-list">
                        {
                            Array.isArray(workingGroups.contains?.list)
                            && workingGroups.contains?.list.map((name: any, index: any) => (
                                <IndustryItem
                                    index={index}
                                    name={name}
                                    // @ts-ignore
                                    key={name}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkingIndustryGroups;
