import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import './style.less';


const { TabPane } = Tabs;

const NavSubMenu = () => {
    const history = useHistory();
    const location = useLocation();
    const [navState, setNavState] = useState('1');

    const goToPage = (key: string) => {
        if (key) {
            history.push(`/${key}`);
            return;
        }

        history.push('/about');
    };

    useEffect(
        () => {
            if (location.pathname && location.pathname !== '/') {
                setNavState(location.pathname?.substring(1));
            } else {
                setNavState('about');
            }
        },
        [location, location.pathname]
    );

    return (
        <Tabs
            defaultActiveKey="1"
            onChange={goToPage}
            className="nav-sub-menu"
            activeKey={navState}
        >
            <TabPane tab="О проекте" key="about" />
            <TabPane tab="Консорциум" key="consortium" />
            <TabPane tab="Обучение" key="education" />
            <TabPane tab="ОПОП и профстандарты" key="actualization" />
            <TabPane tab="Отраслевые рабочие группы" key="working-groups" />
        </Tabs>
    );
};

export default NavSubMenu;
