import React, { ReactElement, useState } from 'react';
import { Spin } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from 'components/button';
import Modal from 'components/modal';
import { ReactComponent as SliderArrowLeft } from 'assets/icons/slider-arrow-left.svg';
import './style.less';


pdfjs.GlobalWorkerOptions.workerSrc = require('react-pdf/dist/esm/pdf.worker.entry');


export interface PresentationViewerModalProps {
    visible: boolean;
    onClose: () => void;
    url: string | undefined;
    title?: string | ReactElement
}

const SliderArrowRight = (props: any) => <SliderArrowLeft {...props} style={{ transform: 'rotate(180deg)' }} />;

const LoadingOverlay = () => (
    <div className="loading-overlay">
        <Spin size="large" />
    </div>
);

export const PresentationViewerModal = ({
    visible, url, onClose, title
} : PresentationViewerModalProps) => {
    const [numPages, setNumPages] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
   
    const onDocLoaded = (pdf: any) => {
        const pages = pdf?.numPages || 0;
        setNumPages(pages);
    };

    const onPageRendered = () => {
        toggleLoading(false);
    };

    const toggleLoading = (value?: boolean) => setLoading((prev) => (value !== undefined ? value : !prev));

    const afterModalClose = () => {
        setPageNumber(1);
        setNumPages(1);
    };

    const onNextPage = () => {
        toggleLoading(true);
        
        // Это необходимо, иначе спинер отображается не корректно,
        // из-за тяжелой подгрузки пдф. По этому я запускаю прогрузку документа
        // после опустошения стека(event-loop)

        setTimeout(() => setPageNumber((page) => (page < numPages ? page + 1 : 1)), 0);
    };
    
    const onPrevPage = () => {
        toggleLoading(true);

        setTimeout(() => setPageNumber((page) => (page > 1 ? page - 1 : numPages)), 0);
    };

    return (
        <Modal
            width={1435}
            centered
            destroyOnClose
            afterClose={afterModalClose}
            visible={visible}
            onCancel={onClose}
            footer={(
                <Button
                    onClick={onClose}
                    type="primary"
                >
                    Закрыть
                </Button>
            )}
            className="presentation-viewer-modal"
            title={title}
        >
            {loading && (
                <LoadingOverlay />
            )}
            <p className="current-page">
                {`Страница ${pageNumber}/${numPages}`}
            </p>
            <div className="viewer-wrapper">
                <SliderArrowLeft className="slider-nav-arrow" onClick={onPrevPage} color="#E4E7F2" />
                <Document
                    loading={<LoadingOverlay />}
                    onLoadSuccess={onDocLoaded}
                    file={url}
                >
                    <Page
                        renderTextLayer={false}
                        loading={<LoadingOverlay />}
                        onRenderSuccess={onPageRendered}
                        width={1255}
                        height={705}
                        pageNumber={pageNumber}
                    />
                </Document>
                <SliderArrowRight className="slider-nav-arrow" onClick={onNextPage} color="#E4E7F2" />
            </div>
        </Modal>
    );
};

export default PresentationViewerModal;
