import { genericApi } from 'store/generic.service';
import { StrapiResponse } from 'utils/converters';


export const consortiumMembersLogosApi:any = genericApi.injectEndpoints({
    endpoints: (builder) => ({
        getConsortiumMembersLogos: builder.query<any, void>({
            query: () => 'consortium-participants?populate=logo',
            transformResponse: (response: StrapiResponse) => {
                const list = response.data.map((record) => {
                    const { name, link, logo } = record.attributes;

                    return {
                        id: record.id,
                        name,
                        link,
                        logo: {
                            id: logo?.data.id,
                            ...logo?.data.attributes
                        }
                    };
                });

                return list;
            }
        })
    })
});

export const {
    useGetConsortiumMembersLogosQuery
} = consortiumMembersLogosApi;
