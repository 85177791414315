import React, { useEffect, useState } from 'react';
import {
    Select, Form, Checkbox, message
} from 'antd';
import { Trans } from 'react-i18next';
import Button from 'components/button';
import Input from 'components/input';
import ErrorBoundaryLine from 'components/error-boundary-line';
import { ErrorBanner } from 'components/error-banner';
import { ReactComponent as SuccessIcon } from 'assets/icons/check-white.svg';
import { ReactComponent as FailIcon } from 'assets/icons/cross_big.svg';
import {
    useGetContactUsFormQuery,
    useSendFeedbackMutation
} from './ContactUs.slice';
import './style.less';


const { Option } = Select;

const initialValues = {
    Subject: 'Выберите тему письма'
};

const RULES = [{ required: true, message: 'Поле обязательно' }];

const SUCCESS_MESSAGE = 'Сообщение отправлено';
const FAIL_MESSAGE = 'При отправке сообщения возникла проблема';
const MESSAGE_CLASSNAME = 'ooc-message';

const ContactUs = () => {
    const { data: captions } = useGetContactUsFormQuery();
    const [
        sendFeedback,
        { isLoading, isError, isSuccess }
    ] = useSendFeedbackMutation();

    const [form] = Form.useForm();
    const [agree, setAgree] = useState(false);
    const [phone, setPhone] = useState<any>({});
    const [email, setEmail] = useState<any>({});

    const onAgreeChange = (e: any) => setAgree(e.target.checked);

    useEffect(() => {
        if (isError) {
            message.warning({
                content: FAIL_MESSAGE,
                icon: <FailIcon />,
                className: `${MESSAGE_CLASSNAME}--fail`
            });
        }
    }, [isError]);

    useEffect(() => {
        if (isSuccess) {
            message.success({
                content: SUCCESS_MESSAGE,
                icon: <SuccessIcon />,
                className: `${MESSAGE_CLASSNAME}--success`
            });
            form.resetFields();
        }
    }, [form, isSuccess]);

    const validateEmail = (text: string) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(text);
    };

    const validatePhone = (text: string) => !text.includes('_');

    const onFormFinish = (values: any) => {
        const success = {
            validateStatus: 'success',
            errorMsg: null,
        };

        const isEmailValid = validateEmail(values.UserEmail);
        if (isEmailValid) {
            setEmail(success);
        } else {
            setEmail({
                validateStatus: 'error',
                errorMsg: 'Введён некорректный адрес электронной почты',
            });
        }

        const isPhoneValid = validatePhone(values.UserPhone);

        if (isPhoneValid) {
            setPhone(success);
        } else {
            setPhone({
                validateStatus: 'error',
                errorMsg: 'Введён некорректный телефон',
            });
        }

        if (!isEmailValid || !isPhoneValid) return;

        sendFeedback(values);
    };

    return (
        <div
            className="contact-us wrapper"
            id="contact-us"
        >
            <div className="content">
                <h4 className="title">
                    {captions?.title}
                </h4>
                <p className="description">
                    {captions?.subTitle}
                </p>
                <div className="form">
                    <Form
                        initialValues={initialValues}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        layout="vertical"
                        form={form}
                        onFinish={onFormFinish}
                    >
                        <Form.Item
                            name="Subject"
                            label={captions?.messageType}
                        >
                            <Select
                                onSelect={(value: string) => form.setFieldsValue({
                                    Subject: value,
                                })}
                                className="form-select"
                            >
                                {
                                    Array.isArray(captions?.typeList)
                                    && (
                                        captions?.typeList.map((item: any) => (
                                            <Option
                                                value={item}
                                                key={item}
                                            >
                                                {item}
                                            </Option>
                                        ))
                                    )
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <div className="user-info">
                                <Form.Item
                                    label={captions?.name}
                                    rules={RULES}
                                    name="UserName"
                                >
                                    <Input />
                                </Form.Item>
                                <Input
                                    required
                                    rules={RULES}
                                    className="form-input"
                                    label={captions?.phone}
                                    name="UserPhone"
                                    type="form"
                                    size="large"
                                    mask="+7 (000) 000-00-00"
                                    maskOptions={{
                                        placeholderChar: 'X'
                                    }}
                                    validateStatus={phone.validateStatus}
                                    help={phone.errorMsg}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            label={captions?.email || 'Электронная почта'}
                            rules={RULES}
                            name="UserEmail"
                            validateStatus={email.validateStatus}
                            help={email.errorMsg}
                            required
                        >
                            <Input placeholder="example@mail.ru" />
                        </Form.Item>
                        <Form.Item
                            rules={RULES}
                            name="Text"
                            label={captions?.message}
                        >
                            <Input
                                textarea
                                rows={8} // FIXME: конфликтует со стилями обычного Input
                                placeholder="Подробно опишите Ваш вопрос"
                            />
                        </Form.Item>
                        <Form.Item className="form-footer">
                            <Form.Item
                                style={{
                                    display: 'inline-block',
                                    marginRight: '16px'
                                }}
                            >
                                <Button
                                    disabled={!agree}
                                    loading={isLoading}
                                    htmlType="submit"
                                    type="primary"
                                >
                                    {captions?.submitText}
                                </Button>
                            </Form.Item>
                            <Form.Item
                                required
                                style={{ display: 'inline-block' }}
                            >
                                <Checkbox
                                    checked={agree}
                                    onChange={onAgreeChange}
                                    className="form-checkbox"
                                >
                                    <Trans
                                        i18nKey="contact-us:agree"
                                        components={{
                                            l: <Button
                                                type="link"
                                                href={captions?.agreementUrl}
                                                target="blank"
                                            />
                                        }}
                                    />
                                </Checkbox>
                            </Form.Item>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <div className="image-wrapper">
                <div>
                    <img src="/images/contact-us.png" alt="" />
                </div>
            </div>
        </div>
    );
};

export default ContactUs;

export const ContactUsSafe = () => (
    <ErrorBoundaryLine
        componentTitle="Обратная связь"
    >
        <ContactUs />
    </ErrorBoundaryLine>
);

export const ContactUsStub = () => <ErrorBanner componentTitle="Обратная связь" />;
