import React from 'react';
import Slider from 'react-slick';
import { useSelector } from 'store/use-selector';
import ErrorBoundaryLine from 'components/error-boundary-line';
import FeatureTitle from 'components/feature-title';
import { FILE_API } from 'utils/request';
import MainEventsItem from './main-events-item';
import { useGetMainEventsQuery } from './MainEvents.slice';
import type { MainEvent } from './types';
import './style.less';


const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const elementsList = (list: MainEvent[] | undefined) => (
    Array.isArray(list)
        ? list.map((item) => (
            <MainEventsItem
                url={item.url}
                image={FILE_API + item.image.url}
                id={item.id}
                key={item.id}
            />
        ))
        : <div />
);

const MainEvents = () => {
    const isMobile = useSelector((state) => state.common.isMobile);
    const { data: list, isError, isLoading } = useGetMainEventsQuery({ pageSize: 6, page: 0 });
    
    if (isError) {
        // TODO: isError | error - ErrorBoundary
        // return Error('Ошибка загрузки главных событий');
    }
    
    if (isLoading) {
        // TODO: isLoading - skeleton
    }

    return (
        <div className="main-events wrapper">
            <FeatureTitle text="Главные события" />
            {
                isMobile
                    ? (
                        <Slider {...sliderSettings}>
                            { elementsList(list) }
                        </Slider>
                    ) : (
                        <div className="main-events__grid" role="grid">
                            { elementsList(list) }
                        </div>
                    )
            }
        </div>
    );
};

export default MainEvents;

export const MainEventsSafe = (props: any) => (
    <ErrorBoundaryLine
        componentTitle="Главные события"
    >
        <MainEvents {...props} />
    </ErrorBoundaryLine>
);
