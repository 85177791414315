import React, { useState } from 'react';
import {
    useGetDigestListQuery,
    useGetDigestHeaderQuery,
} from './Digest.slice';
import DigestItem from './DigestItem';
import Footer from './DigestFooter';
import './Digest.style.less';


const BASE_PAGE = 1;
const BASE_PAGE_SIZE = 3;

export default function Digest() {
    const [page, setPage] = useState(BASE_PAGE);
    const { data: header } = useGetDigestHeaderQuery();
    const { data } = useGetDigestListQuery({
        page, pageSize: BASE_PAGE_SIZE
    });

    const changePage = (newPage: number) => {
        if (
            newPage < 1
            || newPage > data?.pagination.pageCount
        ) return;

        setPage(newPage);
    };

    return (
        <div className="digest wrapper">
            <div className="header">
                <div className="title">
                    {header?.title}
                </div>
                <div className="columns">
                    <div
                        className="col1"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: header?.columnOne,
                        }}
                    />
                    <div
                        className="col2"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: header?.columnTwo,
                        }}
                    />
                </div>
            </div>
            <div className="content">
                {
                    data?.list?.map((item: any) => (
                        <DigestItem
                            title={item.title}
                            date={item.date}
                            file={item.file}
                            logo={item.logo}
                            pos={item.pos}
                            key={item.id}
                        />
                    ))
                }
            </div>
            <Footer
                currentPage={data?.pagination.page}
                totalPages={data?.pagination.pageCount}
                onPrevPage={() => changePage(page - 1)}
                onNextPage={() => changePage(page + 1)}
            />
        </div>
    );
}
