import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store/use-selector';
import Button from 'components/button';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import type { YearTargetItem } from 'store/types-legacy';
import './style.less';


const YearTargetsItem = (props: YearTargetItem) => {
    const history = useHistory();
    const isMobile = useSelector((state) => state.common.isMobile);
    const {
        id, title, linkUrl, linkText, description, data, dataText, image
    } = props;

    const goToPage = (key: string) => {
        if (key) {
            history.push(key);
            return;
        }

        history.push('/about');
    };
    
    return (
        <div className="year-target-item">
            <div>
                <div data-count={`0${id + 1}`} className="item-header">
                    <div className="title">
                        {title}
                    </div>
                    {!isMobile && (
                        <Button
                            type="link"
                            onClick={() => goToPage(linkUrl)}
                        >
                            {linkText}
                            {' '}
                            <ArrowRight />
                        </Button>
                    )}
                </div>
                <div className="item-content">
                    {description}
                </div>
                <div className="item-footer">
                    <span className="count">
                        {data}
                    </span>
                    <span className="label">
                        {dataText}
                    </span>
                </div>
                {isMobile && (
                    <Button
                        type="link"
                        onClick={() => goToPage(linkUrl)}
                    >
                        {linkText}
                        {' '}
                        <ArrowRight />
                    </Button>
                )}
            </div>
            {/* TODO: move images/tasks/*.png to strapi */}
            <img alt="Превью" src={image} />
        </div>
    );
};

export default YearTargetsItem;
