import qs from 'qs';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API } from 'utils/request';
// import type {} from './types';


type ReturnProps = any[];
type Params = void;

const PAGE_SIZE = 100;
const PAGE = 1;

export const regionsApi = createApi({
    reducerPath: 'regionsApi',
    baseQuery: fetchBaseQuery({ baseUrl: API }),
    endpoints: (builder) => ({
        getRegions: builder.query<ReturnProps, Params>({
            query: () => {
                const query = qs.stringify({
                    fields: ['region', 'federal_district'],
                    pagination: {
                        pageSize: PAGE_SIZE,
                        page: PAGE
                    },
                });

                return {
                    url: `regions?${query}`,
                    method: 'get'
                };
            },
            transformResponse: (response: { data: any }) => {
                const { data: list } = response;

                // n * 3 , TODO: optimize regions tree reducer
                const districtArray = Array.from(
                    new Set(
                        list.map(({ attributes: item }: any) => item.federal_district)
                    )
                );
    
                // n * 3
                const newTree = districtArray.map((districtName) => {
                    const arr = list.filter(
                        ({ attributes: item }: any) => item.federal_district === districtName
                    );
    
                    return {
                        title: districtName,
                        key: `=${districtName}`,
                        children: arr.map(({ attributes: item }: any) => ({
                            title: item.region,
                            key: `-${item.region}`
                        }))
                    };
                });
    
                return newTree;
            }
        })
    })
});

export const { useGetRegionsQuery } = regionsApi;
