import React from 'react';
import { useGetAboutPageQuery } from 'store/cms.slice';
import { PageHeaderAbout } from 'features/page-header';
import BannerStatsCards from 'features/banner-stats-cards';
import ProjectTarget from 'features/project-target';
import YearTargets from 'features/year-targets';
import WorkingIndustryGroups from 'features/working-industry-groups';
import PriorityIndustries from 'features/priority-industries';
import { MainEventsSafe } from 'features/main-events';
import CitationBlock from 'features/our-common-task';
import ConsortiumEducationalOrgs from 'features/consortium-educational-orgs';
import Digest from 'features/digest';
import { NewsBlockSafe } from 'features/news-block';
import NewsDrawerProvider from 'components/drawer-news-details';
import ModalConsortiumApply from 'features/modal-consortium-apply';
// import ModalConsortiumUserApply from 'features/modal-consortium-apply-v2';
import './style.less';


const AboutPage = () => {
    const { isError, error } = useGetAboutPageQuery();
    const [modalApplyVisible, setModalApplyVisible] = React.useState(false);
    // const [modalUserApplyVisible, setModalUserApplyVisible] = React.useState(false);
    const openModalApply = () => setModalApplyVisible(true);
    const closeModalApply = () => setModalApplyVisible(false);
    // const toggleUserApply = () => setModalUserApplyVisible((bool) => !bool);

    if (isError) {
        throw Error(error.data);
    }

    return (
        <>
            <NewsDrawerProvider>
                <div className="about-page">
                    {/* <PageHeaderAbout onApply={toggleUserApply} /> */}
                    <PageHeaderAbout onApply={openModalApply} />
                    <div className="page-content-wrapper">
                        <div className="wrapper-content">
                            <BannerStatsCards />
                            <ProjectTarget openModalApply={openModalApply} />
                            <YearTargets />
                            <WorkingIndustryGroups />
                            <PriorityIndustries />
                            <MainEventsSafe />
                            <CitationBlock />
                            <ConsortiumEducationalOrgs />
                            <Digest />
                            <NewsBlockSafe />
                        </div>
                    </div>
                </div>
            </NewsDrawerProvider>
            <ModalConsortiumApply visible={modalApplyVisible} onClose={closeModalApply} />
            {/* <ModalConsortiumUserApply visible={modalUserApplyVisible} onClose={toggleUserApply} /> */}
        </>
    );
};

export default AboutPage;
